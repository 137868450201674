@if (field.touched && field.invalid) {
  <div class="flex label-m items-center cl-error">
    @if (field.errors![formErrors.REQUIRED]) {
      {{ 'FORM_VALIDATION.REQUIRED' | translate }}
    } @else if (field.errors![formErrors.INVALID_PASSWORD]) {
      {{ 'FORM_VALIDATION.INVALID_PASSWORD' | translate }}
    } @else if (field.errors![formErrors.MIN_LENGTH]) {
      {{
        'FORM_VALIDATION.MIN_LENGTH'
          | translate
            : {
                requiredLength:
                  field.errors![formErrors.MIN_LENGTH].requiredLength,
              }
      }}
    } @else if (field.errors![formErrors.MAX_LENGTH]) {
      {{
        'FORM_VALIDATION.MAX_LENGTH'
          | translate
            : {
                requiredLength:
                  field.errors![formErrors.MAX_LENGTH].requiredLength,
              }
      }}
    } @else if (field.errors![formErrors.MIN]) {
      {{
        'FORM_VALIDATION.MIN_VALUE'
          | translate
            : {
                min: field.errors![formErrors.MIN].min,
              }
      }}
    } @else if (field.errors![formErrors.MAX]) {
      {{
        'FORM_VALIDATION.MAX_VALUE'
          | translate
            : {
                max: field.errors![formErrors.MAX].max,
              }
      }}
    } @else if (field.errors![formErrors.PASSWORD_MISMATCH]) {
      {{ 'FORM_VALIDATION.PASSWORD_MISMATCH' | translate }}
    } @else if (field.errors![formErrors.PATTERN]) {
      {{
        'FORM_VALIDATION.PATTERN_' +
          field.errors![formErrors.PATTERN].requiredPattern
          | translateWithFallback
            : 'FORM_VALIDATION.PATTERN'
            : {
                requiredPattern:
                  field.errors![formErrors.PATTERN].requiredPattern,
              }
      }}
    } @else if (field.errors![formErrors.DATE_MUST_BE_GREATER_THAN]) {
      {{
        'FORM_VALIDATION.DATE_MUST_BE_GREATER_THAN'
          | translate
            : {
                field:
                  (field.errors![formErrors.DATE_MUST_BE_GREATER_THAN].field
                  | translate),
                compareField:
                  (field.errors![formErrors.DATE_MUST_BE_GREATER_THAN]
                    .compareField | translate),
              }
      }}
    } @else if (field.errors![formErrors.DATE_MUST_BE_LESS_THAN]) {
      {{
        'FORM_VALIDATION.DATE_MUST_BE_LESS_THAN'
          | translate
            : {
                field:
                  (field.errors![formErrors.DATE_MUST_BE_LESS_THAN].field
                  | translate),
                compareField:
                  (field.errors![formErrors.DATE_MUST_BE_LESS_THAN].compareField
                  | translate),
              }
      }}
    } @else if (field.errors![formErrors.INTEGER]) {
      {{ 'FORM_VALIDATION.INTEGER' | translate }}
    } @else if (field.errors![formErrors.NAME_ALREADY_IN_USE]) {
      {{ 'FORM_VALIDATION.NAME_ALREADY_IN_USE' | translate }}
    } @else if (field.errors![formErrors.EMAIL_ALREADY_IN_USE]) {
      {{ 'FORM_VALIDATION.EMAIL_ALREADY_IN_USE' | translate }}
    } @else if (
      field.errors![formErrors.INVALID_EMAIL] || field.errors![formErrors.EMAIL]
    ) {
      {{ 'FORM_VALIDATION.INVALID_EMAIL' | translate }}
    }
  </div>
}
